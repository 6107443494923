import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

const Index = () => {
    useEffect(() => {
        navigate('/engagewith/');
    }, []);
    return <div></div>;
};

export default Index;
